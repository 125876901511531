import React from 'react';
import './Benefitsv2.css';
import { StaticImage } from 'gatsby-plugin-image';

const check = '../../images/check.png'
const bg = '../../images/bg/br.jpeg'
const save = '../../images/static-imgs/save.webp'
const headache = '../../images/static-imgs/headache.webp'
const wakeUp = '../../images/static-imgs/wake-up.webp'
const cheers = '../../images/static-imgs/cheers.webp'
const bgImage = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/pine-beg.jpg?alt=media&token=893a0dd6-2ad8-45da-a79f-7408483755a2';
const brainfog = '../../images/clear-mind.png'
const nausea = '../../images/nausea.png'
const beerPong = '../../images/beer-pong.png'

export default function BenefitsV2 (){

    return(

        <div className="benefits-v2">
             <div className="benefits-v2-content-header">
                    3 Formulas, Endless Benefits
                </div>
               
            <div className="benefits-v2-mobile-img">
                <StaticImage loading='eager' src = {bgImage}/>
            </div>

            <div className="benefits-v2-content">
               
                <div className="benefits-v2-tr">
                    <div className="benefits-v2-benefit">
                        <div className="benefits-block-image">
                            <StaticImage className="block-image" src={wakeUp} size="small" alt = 'wake up easy with bender'/>
                        </div>
                        <div className="benefits-block-text">
                            <div className="benefit-head">Wake up feeling rested</div>
                            
                        </div>
                    </div>
                    <div className="benefits-v2-benefit">
                        <div className="benefits-block-image">
                            <StaticImage className="block-image" src={cheers} size="small" alt = 'wake up easy with bender'/>
                        </div>
                        <div className="benefits-block-text">
                            <div className="benefit-head">Don’t treat your hangovers, prevent them</div>
                            
                        </div>
                    </div>
                </div>

                <div className="benefits-v2-sr">
                <div className="benefits-v2-benefit">
                        <div className="benefits-block-image">
                            <StaticImage className="block-image" src={headache} size="small" alt = 'wake up easy with bender'/>
                        </div>
                        <div className="benefits-block-text">
                            <div className="benefit-head">Avoid the dreaded hangover headache.</div>
                           
                        </div>
                    </div>
                    <div className="benefits-v2-benefit">
                        <div className="benefits-block-image">
                            <StaticImage className="block-image" src={brainfog} size="small" alt = 'wake up easy with bender'/>
                        </div>
                        <div className="benefits-block-text">
                            <div className="benefit-head">No more hangxiety or brain fog</div>
                           
                        </div>
                    </div>

                </div>

                <div className="benefits-v2-br">
                <div className="benefits-v2-benefit">
                        <div className="benefits-block-image">
                            <StaticImage className="block-image" src={beerPong} size="small" alt = 'wake up easy with bender'/>
                        </div>
                        <div className="benefits-block-text">
                            <div className="benefit-head">Party harder without the consequences</div>
                            
                        </div>
                    </div>
                    <div className="benefits-v2-benefit">
                        <div className="benefits-block-image">
                            <StaticImage className="block-image" src={nausea} size="small" alt = 'wake up easy with bender'/>
                        </div>
                        <div className="benefits-block-text">
                            <div className="benefit-head">Prevents nausea</div>
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )

}