import React from 'react'

//components 
import './Testimonial.css'
import emptyStar from  '../../images/empty-star.png'
import fullStar  from '../../images/star.png'
import halfStar  from '../../images/half-star.png'
import Stack from '@mui/material/Stack';


export default function Review({info}) {
  const {firstName, reviewTitle, reviewContent, reviewRating, date, image} = info
  const productPageStyle = {
    width: '100%',
    marginTop: '5px',
    marginBottom: '5px',
  }

  function ratingToStars(rating){
    let stars = []
    for(let i = 0;  i<5; i++){
      if(i < rating){
        stars.push(1)
      }else{
        stars.push(0)
      }
    }
    let starImgs = stars.map((star,index) => {
      if (star === 1) {
        return <img className="star-icon-small" src={fullStar} alt="star" key = {index}/>
      } else if (star === 0.5) {
        return <img className="star-icon-small" src={halfStar} alt="star" key = {index}/>
      } else if (star === 0) {
        return (
          <img className="star-icon-small" src={emptyStar} alt="star" key = {index}/>
        )
      }
    })
    return starImgs
  }

  function parseDate(dateString){
    let date = new Date(dateString)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let year = date.getFullYear()
    let mdy = `${month}/${day}/${year}`
    return mdy 
  }

  return (
    <div
      className="testimonial-container"
      
    >
      <div className="testimonial-image">
        {image}
      </div>
      <div className = 'testimonial-content'>
        <div className="testimonial-rating">
            {ratingToStars(reviewRating)}
          </div>
        {/*<div className="testimonial-header">{reviewTitle}</div>*/}
        <div className="testimonial-body">{reviewContent}</div>
        <Stack direction={'column'} sx = {{marginTop:'5px'}}>
          <div className="testimonial-name">{firstName}</div>

        </Stack>
      </div>
      
    </div>
  )
}
