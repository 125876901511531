import React from 'react'

//components
import { StaticImage } from 'gatsby-plugin-image'
import Testimonial from '../Testimonial/Testimonial'


//css
import './Testimonials.css'

//images

const claire = '../../images/claire.jpg'
const wein = '../../images/wein_testimonial.jpg'
const lenny = '../../images/lenny.jpg'
const g1 = '../../images/g1_testimonial.jpg'
const placeholderImg = '../../images/strawberry-main.jpeg'
const reviews = [
  
  {
    name: "Owein L.",
    rating: 5,
    header: '',
    body:`I used to dread the morning after a night out. Now I can get hammered with my homies without worrying about how I'll feel the next day. I used to spend so much money on electrolyte drinks from stores the day after drinking, and now I'm always prepared and have extra money for drinks.`,
    image:<StaticImage loading = 'eager' src ={ wein } imgStyle = {{borderTopLeftRadius:'20px', borderTopRightRadius:'20px'}}/>,
  },
  {
    name: 'Claire P.',
    rating: 5,
    header: '',
    body: `This product is amazing! My hangovers are basically nonexistent now. Not only are the ingredients great, but the taste is great, too. All three flavors are on point, especially the mango. You absolutely need Bender for any nights out.   `,
    image:<StaticImage loading = 'eager' src ={claire } imgStyle = {{borderTopLeftRadius:'20px', borderTopRightRadius:'20px'}}/>,
  },
  {
    name: 'Lenny F.',
    rating: 5,
    header: '',
    body: `This product has been an absolute game changer for my summer! I am completely blown away by how well it works. Not only does it work, it’s absolutely delicious too. As someone who used to experience terrible hangovers, waking up on a Sunday morning feeling good and sharp has been the best thing in the world to me. If you haven’t got some yet, I don’t know what the hell you’re waiting for!`,
    image:<StaticImage loading = 'eager' src ={lenny} imgStyle = {{borderTopLeftRadius:'20px', borderTopRightRadius:'20px'}}/>,
  },
  {
    name: 'Lily F.',
    rating: 5,
    header: '',
    body: `Bender is an absolute necessity. After a long night of Jäger bombs, Bender truly saved the day. I usually wake up nauseous after a night of drinking but the pre-game and post-game made the morning a lot less dreadful. The plan- b was essential in the morning giving me the energy and boost I needed. The blasted blue raspberry was my favorite of my three flavors but the mango and pineapple were delicious as well. Bender is a must buy.`,
    image:<StaticImage loading = 'eager' src ={ g1 }  imgStyle = {{borderTopLeftRadius:'20px', borderTopRightRadius:'20px'}}/>,
  },
  /*{
    name: 'Lenny',
    rating: 5,
    header: 'God bless Bender mafia',
    body: `Gotta say boys the bender works pretty good🤝🤝 \n \n I went particularly hard with mixed liquors and sugar to give it a real test and my stomach and head feel completely fine rn. Might be mental but I think the plan-b was the most important step because I felt kinda rattled when I woke up and after the plan b felt really good within like 10 min.`,
    image:<StaticImage src ={placeholderImg }/>,
  },*/
]

export default function Testimonials({version, handleCTA}) {
  return (
    <div className="testimonials-container">

      


      <div className ='testimonials-header'>Our Customers Love Us</div>
      <div
        className={
          version === 'landing-page'
            ? 'testimonials-reviews'
            : 'pp-testimonials-reviews'
        }
      >
       
     
          {reviews.map(review => (
            <Testimonial info ={{firstName:review.name, reviewTitle:review.header, reviewContent:review.body, reviewRating:review.rating, date:'', image:review.image}} key={review.name} />
          ))}
     
        
      </div>
    </div>
  )
}
