import React from 'react';

import '../Testimonials/Testimonials.css';
import './ValueProp1.css';
import Cta from '../CTA/CTA';
import { motion } from "framer-motion";
import { StaticImage } from 'gatsby-plugin-image';

const blue = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/blue.jpeg?alt=media&token=bec18c02-900d-4c99-a7c2-f1e5971a552d'

export default function ValueProp1 ({handleCTA, buttonVariant, customStyle}){

    const container = {
        offscreen: { opacity: 0 },
        onscreen: {
          opacity: 1,
          transition: {
           staggerChildren:0,
           duration:2,
          }
        }
      }

    const item = {
        offscreen: {
            opacity: 0,
            y:50,
          },
        onscreen: { 
          y:0,
          opacity: 1,
          transition:{
            duration:1,
          }
        },
        
      }

    return(

        <motion.div className='vp1-container' variants={container} style = {customStyle}>
            <motion.div variants = {item} className="testimonials-upper-section" style={{height:'100%',justifyContent:'center', alignItems:'center',}}>
                <div  className="testimonials-upper-section-l">
                <div className="testimonials-upper-section-l-title">
                  Taking a stand against hangovers
                </div>
                <div className='testimonials-upper-section-l-text'>
                   Better value. Better ingredients. Better hangover prevention. The Bender promise.
                </div>
                <div style={{marginTop:'20px'}}>
                    <Cta handleCTA = {handleCTA} variant = {buttonVariant}/>
                </div>
                </div>
                <div className="testimonials-upper-section-r">
                <div className="testimonials-upper-section-r-img-c" style={{overflowX:'hidden', overflowY:'hidden'}}>
                    <StaticImage loading='eager' objectFit = 'cover' objectPosition="50% 25%" src ={blue} imgStyle = {{height:'100%', width:'auto', borderRadius:"20px" }}/>
                </div>
                </div>

            </motion.div>
        </motion.div>

    )

}